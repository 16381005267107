import {Modal} from '@focusrite-novation/ampify-web-ui';
import React, {FC} from 'react';
import styled from 'styled-components';

export interface TermsModalProps {
  handleClose: () => void;
}

const Paragraph = styled.p`
  font-family: Lato, sans-serif;
`;

export const TermsModal: FC<TermsModalProps> = ({handleClose}) => {
  return (
    <Modal
      handleClose={handleClose}
      closeButtonId="bf-modal-close"
      wrapperDataTestid="terms-modal"
      maxWidth="600px"
      header={() => <h4 data-testid="banner-header">Terms and Conditions</h4>}
    >
      <Paragraph data-testid="terms-text">
        Enter code BF50 at check-out to receive 50% discount.
      </Paragraph>
      <Paragraph>Ends 8am GMT on December 6th 2021.</Paragraph>
      <Paragraph>
        Code can be used once per order on either 15-month payment plan or
        one-time payment to purchase FAST Bundle or individual FAST Plug- ins.
        This offer cannot be used in conjunction with any other offers or
        discounts.
      </Paragraph>
    </Modal>
  );
};
