import React, {FunctionComponent} from 'react';
import {IconProps} from './IconTypes';
import {Theme} from '../../theme/Theme';

export const MacOSIcon: FunctionComponent<IconProps> = ({
  colour = Theme.colours.white,
  width = '19px',
  height = '26px',
}) => (
  <svg width={width} height={height} viewBox="0 0 21 26">
    <g fill={colour} fillRule="nonzero">
      <path d="M17.22309 13.82127c-.03192-3.29199 2.61575-4.8709 2.73414-4.95066-1.48803-2.23287-3.80628-2.53925-4.63137-2.57412-1.97134-.20548-3.84848 1.19171-4.84857 1.19171-.99802 0-2.54318-1.1616-4.1779-1.13202-2.15046.03276-4.13261 1.28363-5.23924 3.25925-2.23333 3.97606-.57133 9.86858 1.60487 13.0956 1.0639 1.57732 2.33267 3.35221 3.99724 3.28724 1.60435-.06498 2.20965-1.06493 4.14908-1.06493 1.93943 0 2.484 1.06493 4.181 1.03165 1.7253-.03222 2.81906-1.60744 3.87422-3.1911 1.2214-1.83247 1.72428-3.60682 1.75413-3.69663-.03757-.01796-3.36312-1.32535-3.3976-5.25599zM14.72844 3.72056C15.6536 2.73441 16.28042 1.36413 16.10995 0c-1.33512.04737-2.95129.77916-3.90988 1.76437-.8588.87436-1.6097 2.26643-1.40848 3.60593 1.4894.10136 3.00847-.66548 3.93685-1.64974z" />
    </g>
  </svg>
);
