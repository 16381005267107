import styled from 'styled-components';
import {Theme} from '../../theme/Theme';

const {
  colours,
  space,
  fontSizes,
  lineHeights,
  borderWidths,
  breakpoints,
} = Theme;

export const FooterContainer = styled.div`
  padding: ${space[6]}px 0 ${space[6]}px 0;
  background: ${colours.black};
  min-height: ${space[8]}px;
  width: 100%;
  color: ${colours.white};
`;

export const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${space[6]}px;
  span {
    width: max-content;
  }
  @media screen and (min-width: ${breakpoints[1]}px) {
    padding-right: ${space[6]}px;
    padding-bottom: 0;
  }
`;

export const Link = styled.a`
  color: ${colours.white};
  font-size: ${fontSizes[2]}px;
  line-height: ${lineHeights.body}px;
  text-decoration: none;
  :after {
    position: relative;
    top: -2px;
    content: ' ';
    display: block;
    background: ${colours.white};
    height: ${borderWidths[0]}px;
    width: 0;
    transition: width 180ms ease-out;
  }

  :hover {
    :after {
      width: 100%;
    }
  }
`;

export const LinkGroups = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: max-content;

  @media screen and (min-width: ${breakpoints[1]}px) {
    flex-direction: row;
    margin: auto;
    width: auto;
    ${LinkGroup} {
      :nth-last-child(2) {
        flex-grow: 2;
      }
    }
  }
`;
