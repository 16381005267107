import {LabeledNavLink} from '../navigation.types';
import React, {FunctionComponent} from 'react';
import {SubNavAnchor} from './SubNav.styles';
import {SubNavLinkWrapper} from './SubNavLink.styles';

interface SubNavItemProps extends LabeledNavLink {}

export const SubNavLink: FunctionComponent<SubNavItemProps> = ({
  link,
  label,
  ...props
}) => (
  <SubNavLinkWrapper>
    <SubNavAnchor href={link} {...props} tabIndex={0}>
      <span>{label}</span>
    </SubNavAnchor>
  </SubNavLinkWrapper>
);
