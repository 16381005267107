import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {Theme} from '../../../theme/Theme';
import {SubNavLinkWrapper} from './SubNavLink.styles';
import {rgba} from 'polished';

const {colours, borderWidths, breakpoints, fonts, fontSizes, space} = Theme;

const List = styled.ul`
  list-style: none outside none;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin: 0;
  user-select: none;

  @media screen and (min-width: ${breakpoints[0]}px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

interface SubNavListProps {
  expanded: boolean;
  selectedNavGroupIndex: number;
}

export const SubNavList = styled(List)<SubNavListProps>`
  display: ${({expanded}) => (expanded ? 'flex' : 'none')};
  padding: ${space[4]}px 0;
  overflow-y: auto;
  font-weight: bold;

  @media screen and (min-width: ${breakpoints[0]}px) {
    display: block;
    padding: 0;
    display: ${({selectedNavGroupIndex}) =>
      selectedNavGroupIndex > -1 ? 'flex' : 'none'};
  }

  @media screen and (max-width: ${breakpoints[0]}px) {
    height: ${({selectedNavGroupIndex}) =>
      selectedNavGroupIndex > -1 ? '1000px' : 'auto'};
  }
`;

export const SubNavGroupList = styled(List)`
  padding: 0;
  a {
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints[0]}px) {
    a {
      padding-top: 0;
    }
  }
  ${SubNavLinkWrapper} {
    border-top: ${rgba(colours.white, 0.3)} solid ${borderWidths[0]}px;
    a {
      padding-left: ${space[3]}px;
    }
  }
  @media screen and (min-width: ${breakpoints[0]}px) {
    display: flex;
    justify-content: flex-end;
    ${SubNavLinkWrapper} {
      border-top: none;
      a {
        padding-left: 0;
      }
    }
  }
`;

interface SubNavItemWrapperProps {
  onlyVisibleOnMobile?: boolean;
  isVisibleOnDesktop?: boolean;
}

export const SubNavItemWrapper = styled.div<SubNavItemWrapperProps>`
  border-top: ${colours.white} solid ${borderWidths[0]}px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-right: 0;
  transition: padding 400ms;

  :last-child {
    border-bottom: ${colours.white} solid ${borderWidths[0]}px;
  }

  @media screen and (min-width: ${breakpoints[0]}px) {
    border: none;
    display: ${({onlyVisibleOnMobile, isVisibleOnDesktop}) =>
      onlyVisibleOnMobile || !isVisibleOnDesktop ? 'none' : 'flex'};
  }
  @media screen and (min-width: ${breakpoints[1]}px) {
    width: 100%;
  }
`;

export const SubNavAnchor = styled.a`
  cursor: pointer;
  color: ${colours.white};
  text-decoration: none;
  font-family: ${fonts.heading};
  font-weight: bold;
  font-size: ${fontSizes[4]}px;
  padding: ${space[4]}px 0;
  width: 100%;

  @media screen and (min-width: ${breakpoints[0]}px) {
    :after {
      content: ' ';
      display: block;
      background: ${colours.white};
      height: ${borderWidths[1]}px;
      width: 0;
      transition: width 230ms ease-out;
    }

    padding: 0;
    width: auto;
    font-size: ${fontSizes[2]}px;
    :hover {
      :after {
        width: 100%;
      }
    }
  }
`;
