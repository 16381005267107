import styled, {keyframes} from 'styled-components';
import {IconProps} from '../Icon/IconTypes';
import React, {FunctionComponent} from 'react';
import {Theme} from '../../theme/Theme';

interface AnimatedLetterProps {
  delay: number;
}

const TheCollectiveNameSVG = styled.svg`
  margin-left: ${Theme.space[2]}px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const PolygonLetter = styled.polygon<AnimatedLetterProps>`
  opacity: 0;
  animation: ${fadeIn} 280ms ease-in forwards;
  animation-delay: ${({delay}) => delay}ms;
`;

const PathLetter = styled.path<AnimatedLetterProps>`
  opacity: 0;
  animation: ${fadeIn} 280ms ease-in forwards;
  animation-delay: ${({delay}) => delay}ms;
`;

interface TheCollectiveNameProps extends IconProps {
  delay: number;
}

export const TheCollectiveName: FunctionComponent<TheCollectiveNameProps> = ({
  colour = Theme.colours.white,
  width = '100px',
  height = '70px',
  delay,
}) => (
  <TheCollectiveNameSVG
    height={height}
    width={width}
    fill={colour}
    viewBox="0 0 959 206"
  >
    <PolygonLetter
      delay={delay + 0}
      fill={colour}
      points="24.6 79.2 42 79.2 42 16.3 66 16.3 66 0.3 0.5 0.3 0.5 16.3 24.5 16.3 24.5 79.2"
    />
    <PolygonLetter
      delay={delay + 10}
      fill={colour}
      points="104.1 79.2 121.5 79.2 121.5 47.5 153.5 47.5 153.5 79.2 170.9 79.2 170.9 0.2 153.5 0.2 153.5 31.4 121.5 31.4 121.5 0.2 104.1 0.2"
    />
    <PolygonLetter
      delay={delay + 20}
      fill={colour}
      points="214.6 79.2 274.7 79.2 274.7 63.7 231.8 63.7 231.8 47.1 269 47.1 269 31.6 231.8 31.6 231.8 15.6 274.1 15.6 274.1 0.1 214.5 0.1 214.5 79.2"
    />
    <PathLetter
      delay={delay + 30}
      d="M41,205.1 C56.2,205.1 65.3,199.7 73.4,191 L62.3,179.8 C56.1,185.4 50.6,189.1 41.5,189.1 C28,189.1 18.6,177.8 18.6,164.3 L18.6,164.1 C18.6,150.6 28.2,139.5 41.5,139.5 C49.4,139.5 55.6,142.9 61.7,148.4 L72.8,135.7 C65.5,128.5 56.6,123.5 41.7,123.5 C17.4,123.5 0.5,141.9 0.5,164.3 L0.5,164.5 C0.5,187.2 17.8,205.1 41,205.1 Z"
      fill={colour}
    />
    <PathLetter
      delay={delay + 40}
      d="M148.5,123.5 C172.8,123.5 190.3,141.7 190.3,164.1 L190.3,164.1 L190.3,164.3 C190.3,186.7 172.6,205.1 148.2,205.1 C123.8,205.1 106.3,187 106.399574,164.5 L106.399574,164.5 L106.399574,164.3 C106.399574,141.9 124.2,123.5 148.5,123.5 Z M148.2,139.5 C134.2,139.5 124.5,150.6 124.5,164.1 L124.5,164.1 L124.5,164.3 C124.5,177.8 134.4,189.1 148.4,189.1 C162.4,189.1 172.1,178.1 172.1,164.5 L172.1,164.5 L172.1,164.3 C172.1,150.8 162.2,139.5 148.2,139.5 Z"
      id="txt5"
      fill={colour}
    />
    <PolygonLetter
      delay={delay + 50}
      fill={colour}
      points="230.5 203.8 287.2 203.8 287.2 188 247.8 188 247.8 124.8 230.5 124.8"
    />
    <PolygonLetter
      delay={delay + 60}
      fill={colour}
      points="325.1 203.8 381.8 203.8 381.8 188 342.4 188 342.4 124.8 325 124.8 325 203.8"
    />
    <PolygonLetter
      delay={delay + 70}
      fill={colour}
      points="419.7 203.8 479.8 203.8 479.8 188.3 436.9 188.3 436.9 171.7 474.1 171.7 474.1 156.2 436.9 156.2 436.9 140.2 479.2 140.2 479.2 124.7 419.6 124.7 419.6 203.8"
    />
    <PathLetter
      delay={delay + 80}
      d="M557,205.1 C572.2,205.1 581.3,199.7 589.4,191 L578.3,179.8 C572.1,185.4 566.6,189.1 557.5,189.1 C544,189.1 534.6,177.8 534.6,164.3 L534.6,164.1 C534.6,150.6 544.2,139.5 557.5,139.5 C565.4,139.5 571.6,142.9 577.7,148.4 L588.8,135.7 C581.5,128.5 572.6,123.5 557.7,123.5 C533.4,123.5 516.5,141.9 516.5,164.3 L516.5,164.5 C516.5,187.2 533.8,205.1 557,205.1 Z"
      fill={colour}
    />
    <PolygonLetter
      delay={delay + 90}
      fill={colour}
      points="646.4 203.8 663.8 203.8 663.8 140.9 687.8 140.9 687.8 124.9 622.4 124.9 622.4 140.9 646.4 140.9"
    />
    <PolygonLetter
      delay={delay + 100}
      fill={colour}
      points="726.7 203.8 744.1 203.8 744.1 124.8 726.7 124.8"
    />
    <PolygonLetter
      delay={delay + 110}
      fill={colour}
      points="813.8 204.3 829.1 204.3 861 124.8 842.3 124.8 821.7 180.4 801.1 124.8 781.9 124.8"
    />
    <PolygonLetter
      delay={delay + 120}
      fill={colour}
      points="898 203.8 958.1 203.8 958.1 188.3 915.2 188.3 915.2 171.7 952.4 171.7 952.4 156.2 915.2 156.2 915.2 140.2 957.5 140.2 957.5 124.7 897.9 124.7 897.9 203.8"
    />
  </TheCollectiveNameSVG>
);
