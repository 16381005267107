/**
 * Style Props
 * https://styled-system.com/theme-specification/
 *
 * Placeholder styles while waiting for designs
 */

export const Theme = {
  colours: {
    white: '#FFFFFF',
    darkGrey: '#1D1D1B',
    grey: '#212829',
    greys: [
      '#F5F5F5',
      '#EFF1F1',
      '#D2D5DA',
      '#BBC5C7',
      '#909C9E',
      '#5A6E73',
      '#435154',
      '#303839',
      '#212829',
      '#14191A',
    ],
    lightGrey: '#9A9A9A',
    lightestGrey: '#e7e7e7',
    black: '#212829',
    blue: '#499BAE',
    red: '#C34C54',
    orange: '#E28B4E',
    yellow: '#E7CA65',
    transparent: 'rgba(0,0,0,0)',
  },

  fonts: {
    heading: `Montserrat, sans-serif`,
    body: `Lato, sans-serif`,
  },

  //          xs  sm  p   h6  h5  h4  h3  h2  h1
  fontSizes: [12, 14, 16, 18, 18, 18, 24, 36, 48],

  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },

  lineHeights: {
    body: 28,
    //        h6  h5  h4  h3  h2  h1
    heading: [18, 18, 18, 26, 42, 52],
  },

  space: [0, 4, 8, 16, 24, 32, 64, 128, 184, 256],

  radii: [0, 4, 8, 16, 26, 32, 64, 128],

  borderWidths: [1, 3, 6, 12],

  shadows: [
    '0 1px 3px rgba(0,0,0,0.12)',
    '0px 6px 8px 0px rgba(0,0,0,0.2)',
    '0px 6px 8px 0px rgba(0,0,0,0)',
  ],

  zIndices: {
    nav: 900,
    modal: 999,
    behind: -1,
  },

  breakpoints: [576, 768, 992, 1200],
};
