export const actions = {
  myAccount: 'MyAccount',
  buy: 'Buy',
};

export interface PurchaseUrlOptions {
  customerPortalBaseUrl: string;
  productName: string;
  planId?: string;
  softwareId: string;
  slug: string;
  itemId?: string;
}

export const buildPurchaseUrl = (options: PurchaseUrlOptions): string => {
  return urlForState(options.customerPortalBaseUrl, {
    action: actions.buy,
    ...options,
  });
};

export const myAccountUrl = (customerPortalBaseUrl: string) => {
  return urlForState(customerPortalBaseUrl, {action: actions.myAccount});
};

export const getHostname = () => {
  if (window?.location?.hostname) {
    if (window.location.hostname === 'localhost') {
      return 'collective-test.focusrite.com';
    }

    return window.location.hostname;
  }

  return 'localhost';
};

export const urlForState = (
  customerPortalBaseUrl: string,
  state?: any
): string => {
  const redirectUri = `https://${getHostname()}/oauth`;
  const stateParameter = state ? window.btoa(JSON.stringify(state)) : 'none';
  const query = Object.entries({
    client_id: 'snpm4ju3tf2m6p5p',
    response_type: 'code',
    redirect_uri: redirectUri,
    scope: 'email profile openid offline_access',
    state: stateParameter,
  })
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return `${customerPortalBaseUrl}/oauth2/authorize?${query}`;
};
