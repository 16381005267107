import React, {FunctionComponent} from 'react';
import {NavLinks} from '../navigation.types';
import {
  List,
  ListItem,
  MenuToggleContainer,
  Nav,
  NavGroupLink,
  NavLink,
} from './MainNav.styles';
import {MenuToggle} from '../../MenuToggle';
import {detect, DeviceOS} from '../../../utils/detect';
import {navigate} from 'gatsby-link';

interface MainNavProps {
  /** the config that defines the links to show */
  links: NavLinks;
  /** when the menu is expanded */
  menuExpanded: boolean;
  /** the index of the selected nav group link */
  selectedNavGroupIndex: number;
  /** when a group is selected or deselected */
  onSelectedNavGroup: (index: number) => void;
  /** toggle the menu open and closed */
  onToggleMenu: () => void;
}

export const MainNav: FunctionComponent<MainNavProps> = ({
  links,
  selectedNavGroupIndex,
  menuExpanded,
  onSelectedNavGroup,
  onToggleMenu,
}) => {
  const handleDropDown = (index: number) => {
    onSelectedNavGroup(selectedNavGroupIndex === index ? -1 : index);
  };
  return (
    <Nav>
      <List>
        {links.map((link, index) => {
          const id = `main-nav-${index}`;
          return (
            <ListItem key={id}>
              {'link' in link && link.link !== null ? (
                <NavLink href={link.link} tabIndex={0} data-testid={id}>
                  {link.label}
                </NavLink>
              ) : (
                <NavGroupLink
                  data-testid={id}
                  selected={selectedNavGroupIndex === index}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleDropDown(index);
                    }
                  }}
                  onMouseEnter={
                    detect.isDesktop() && detect.os() !== DeviceOS.IPAD_OS
                      ? () => onSelectedNavGroup(index)
                      : undefined
                  }
                  onClick={() => navigate('/products')}
                >
                  {link.label}
                </NavGroupLink>
              )}
            </ListItem>
          );
        })}
      </List>
      <MenuToggleContainer>
        <MenuToggle
          open={!menuExpanded}
          onClick={onToggleMenu}
          data-testid="main-nav-toggle"
        />
      </MenuToggleContainer>
    </Nav>
  );
};
