import React, {FunctionComponent} from 'react';
import {NavLinks} from '../navigation.types';
import {SubNavList, SubNavItemWrapper} from './SubNav.styles';
import {SubNavGroup} from './SubNavGroup';
import {SubNavLink} from './SubNavLink';

interface SubNavProps {
  /** the navigation links */
  links: NavLinks;
  /** the index of the selected nav group link */
  selectedNavGroupIndex: number;
  /** when a group is selected or deselected */
  onSelectedNavGroup: (index: number) => void;
  /** when the menu is expanded on mobile */
  expanded?: boolean;
}

export const SubNav: FunctionComponent<SubNavProps> = ({
  links,
  selectedNavGroupIndex,
  expanded,
  onSelectedNavGroup,
}) => (
  <SubNavList
    expanded={!!expanded}
    selectedNavGroupIndex={selectedNavGroupIndex}
  >
    {links.map((link, index) => {
      const id = `sub-nav-${index}`;
      return 'link' in link && link.link !== null ? (
        <SubNavItemWrapper key={id} onlyVisibleOnMobile>
          <SubNavLink {...link} data-testid={id} />
        </SubNavItemWrapper>
      ) : 'links' in link ? (
        <SubNavItemWrapper
          key={id}
          isVisibleOnDesktop={selectedNavGroupIndex === index}
        >
          <SubNavGroup
            {...link}
            testID={id}
            open={selectedNavGroupIndex === index}
            onClick={() =>
              onSelectedNavGroup(selectedNavGroupIndex === index ? -1 : index)
            }
          />
        </SubNavItemWrapper>
      ) : null;
    })}
  </SubNavList>
);
