import React, {FunctionComponent} from 'react';
import {IconProps} from './IconTypes';
import {Theme} from '../../theme/Theme';
import {Dictionary} from '../../types/Dictionary';
import styled from 'styled-components';

interface ArrowIconProps extends IconProps {
  orientation?: Orientation;
}

export enum Orientation {
  UP,
  DOWN,
  RIGHT,
  LEFT,
}

const rotation: Dictionary<number> = {
  [Orientation.DOWN]: 0,
  [Orientation.RIGHT]: 90,
  [Orientation.UP]: 180,
  [Orientation.LEFT]: 270,
};

const Polygon = styled.polygon``;

export const ArrowIcon: FunctionComponent<ArrowIconProps> = ({
  colour = Theme.colours.white,
  width = '19px',
  height = '19px',
  orientation = Orientation.DOWN,
  ...props
}) => (
  <svg width={width} height={height} viewBox="0 0 19 19" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <Polygon
        id="Path"
        fill={colour}
        fillRule="nonzero"
        transform={`translate(9.500000, 9.500000) rotate(${rotation[orientation]}) translate(-9.500000, -9.500000)`}
        points="9.5 13.466665 18 5.533335 1 5.533335"
      />
    </g>
  </svg>
);
