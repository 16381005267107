import React, {FunctionComponent} from 'react';
import {IconProps} from './IconTypes';
import {Theme} from '../../theme/Theme';

export const WindowsIcon: FunctionComponent<IconProps> = ({
  colour = Theme.colours.white,
  width = '25px',
  height = '25px',
}) => (
  <svg width={width} height={height} viewBox="0 0 23 23">
    <path
      fill={colour}
      fillRule="nonzero"
      d="M0 19.66864l9.2799 1.34405v-8.87832H0v7.53427zm0-8.80254h9.2799V1.98684L0 3.33089v7.53521zm10.5482 10.33045L23 23V12.13437H10.5482v9.06218zm0-19.39403v9.06358H23V0L10.5482 1.80252z"
    />
  </svg>
);
