import React, {FunctionComponent, useEffect, useState} from 'react';
import {StyledHeader, StyledGutters, Bar} from './Header.styles';
import {GutterMaxWidth, Gutters} from '../Spacing/Gutters';
import {MainNav, SubNav} from '../navigation';
import {NavLinks} from '../navigation/navigation.types';
import {TheCollectiveLogo} from '../TheCollectiveLogo/';
import {createGlobalStyle} from 'styled-components';
import {Theme} from '../../theme/Theme';
import {BlackFridayBanner} from '../BlackFridayBanner';
import {PaymentsApi} from '@focusrite-novation/ampify-api';

interface HeaderProps {
  animate?: boolean;
  links: NavLinks;
  showBanner: boolean;
}

const DisableScroll = createGlobalStyle`
  html, body {
    overflow: hidden;
    margin: 0;
    height: 100%;
  }

  @media screen and (min-width: ${Theme.breakpoints[0]}px) {
    html, body {
      overflow: auto;
      margin: auto;
      height: auto;
    }
  }
`;

export const Header: FunctionComponent<HeaderProps> = ({
  animate,
  links,
  showBanner,
}) => {
  const [thinMode, setThinMode] = useState<boolean>(false);
  const [navExpanded, setNavExpanded] = useState<boolean>(false);
  const [selectedNavGroupIndex, setSelectedNavGroupIndex] =
    useState<number>(-1);

  useEffect(() => {
    const handleScroll = () => {
      setThinMode((thinMode) => {
        setSelectedNavGroupIndex(-1);
        if (window.scrollY > 120 && !thinMode) {
          return true;
        } else if (window.scrollY < 50 && thinMode) {
          return false;
        }
        return thinMode;
      });
      if (selectedNavGroupIndex !== -1) {
        setSelectedNavGroupIndex(-1);
      }
    };

    const handleOrientationChange = () => {
      setNavExpanded(false);
      setSelectedNavGroupIndex(-1);
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === 'Tab' && !navExpanded) {
        setNavExpanded(true);
        setSelectedNavGroupIndex(0);
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <StyledHeader
      data-testid={'site-header'}
      thinMode={thinMode}
      onMouseLeave={() => {
        setSelectedNavGroupIndex(-1);
        setNavExpanded(false);
      }}
    >
      {showBanner && <BlackFridayBanner />}
      {navExpanded && <DisableScroll />}
      <StyledGutters
        thinMode={thinMode}
        maxWidth={GutterMaxWidth.LARGE}
        paddingSides
      >
        <TheCollectiveLogo small={thinMode} light href="/" />
        <MainNav
          links={links}
          selectedNavGroupIndex={selectedNavGroupIndex}
          menuExpanded={navExpanded}
          onToggleMenu={() => {
            navExpanded && setSelectedNavGroupIndex(-1);
            setNavExpanded(!navExpanded);
          }}
          onSelectedNavGroup={(index) => {
            setSelectedNavGroupIndex(index);
            setNavExpanded(true);
          }}
        />
      </StyledGutters>
      <Bar
        animate={animate}
        expanded={navExpanded}
        expandedOnDesktop={selectedNavGroupIndex > -1}
      >
        <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
          <SubNav
            links={links}
            selectedNavGroupIndex={selectedNavGroupIndex}
            expanded={navExpanded}
            onSelectedNavGroup={(index) => setSelectedNavGroupIndex(index)}
          />
        </Gutters>
      </Bar>
    </StyledHeader>
  );
};
