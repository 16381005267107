import React, {FunctionComponent} from 'react';
import {IconProps} from './IconTypes';
import {Theme} from '../../theme/Theme';
import styled from 'styled-components';

interface MenuIconProps extends IconProps {
  close?: boolean;
}

const Polygon = styled.polygon`
  transition: transform 100ms ease-out;
`;

export const MenuIcon: FunctionComponent<MenuIconProps> = ({
  colour = Theme.colours.white,
  width = '24px',
  height = '24px',
  close,
  ...props
}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 24 24"
    fill={colour}
    {...props}
  >
    <Polygon
      fillRule="nonzero"
      points="3 18 21 18 21 16 3 16"
      transform={`rotate(${close ? -45 : 0},6,15)`}
    />
    <Polygon
      fillRule="nonzero"
      points="3 13 21 13 21 11 3 11"
      transform={`rotate(${close ? 45 : 0},12,12)`}
    />

    <Polygon
      fillRule="nonzero"
      points="3 6 3 8 21 8 21 6"
      transform={`rotate(${close ? 45 : 0},6,9)`}
    />
  </svg>
);
