import styled from 'styled-components';
import {Theme} from '../../../theme/Theme';

const {colours, breakpoints, fonts, fontSizes, space} = Theme;

export const SubNavGroupWrapper = styled.div`
  padding: 0;
  width: 100%;

  @media screen and (min-width: ${breakpoints[0]}px) {
    border: none;
    padding: ${space[3]}px 0;
  }
`;

interface SubNavGroupToggleProps {
  open?: boolean;
}

export const SubNavGroupToggle = styled.div<SubNavGroupToggleProps>`
  cursor: pointer;
  color: ${colours.white};
  font-family: ${fonts.heading};
  font-size: ${fontSizes[4]}px;
  padding: ${space[4]}px 0;

  display: flex;
  flex-direction: row;
  svg {
    flex-grow: 0;
  }
  span {
    flex-grow: 2;
  }

  transition: background 200ms ease-out;
  background: ${colours.transparent};

  :active {
    background: ${colours.grey};
  }

  @media screen and (min-width: ${breakpoints[0]}px) {
    font-size: ${fontSizes[2]}px;
    display: none;
  }
`;
