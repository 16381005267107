import React, {FunctionComponent} from 'react';
import {MenuIcon} from '../Icon/MenuIcon';

interface MenuToggleProps {
  /** when the menu is open */
  open?: boolean;
  /** event handler for when the button is clicked */
  onClick?: () => void;
}

export const MenuToggle: FunctionComponent<MenuToggleProps> = ({
  open,
  ...props
}) => <MenuIcon width="40px" height="40px" close={!open} {...props} />;
