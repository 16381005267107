import React, {FunctionComponent} from 'react';
import {GutterMaxWidth, Gutters} from '../Spacing/Gutters';
import {FooterContainer, Link, LinkGroup, LinkGroups} from './Footer.styles';
import {FooterContent} from '../../types/FooterContent';

interface FooterProps extends FooterContent {}

export const Footer: FunctionComponent<FooterProps> = ({groupedLinks}) => (
  <FooterContainer>
    <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
      <LinkGroups>
        {groupedLinks.map(({title, links}, index) => (
          <LinkGroup key={`footer-links-${index}`}>
            <h4>{title}</h4>
            {links.map(({label, url}, linkIndex) => (
              <span key={`link-${index}-${linkIndex}`}>
                <Link href={url}>{label}</Link>
              </span>
            ))}
          </LinkGroup>
        ))}
      </LinkGroups>
    </Gutters>
  </FooterContainer>
);
