import React, {FunctionComponent} from 'react';
import {SubNavGroupList} from './SubNav.styles';
import {ArrowIcon} from '../../Icon';
import {Orientation} from '../../Icon/ArrowIcon';
import {LabeledNavLinks} from '../navigation.types';
import {SubNavLink} from './SubNavLink';
import {SubNavGroupToggle, SubNavGroupWrapper} from './SubNavGroup.styles';

interface SubLinkGroupProps extends LabeledNavLinks {
  open: boolean;
  onClick: () => void;
  testID: string;
}

export const SubNavGroup: FunctionComponent<SubLinkGroupProps> = ({
  links,
  label,
  open,
  onClick,
  testID,
}) => (
  <SubNavGroupWrapper>
    <SubNavGroupToggle onClick={onClick} open={open} data-testid={testID}>
      <span>{label}</span>
      <ArrowIcon orientation={open ? Orientation.UP : Orientation.DOWN} />
    </SubNavGroupToggle>
    <SubNavGroupList>
      {open &&
        links.map((link, index) => (
          <SubNavLink
            {...link}
            key={`sub-nav-link-${index}`}
            data-testid={`${testID}-${index}`}
          />
        ))}
    </SubNavGroupList>
  </SubNavGroupWrapper>
);
