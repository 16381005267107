import styled, {css, keyframes} from 'styled-components';
import {AnchorHTMLAttributes, DetailedHTMLProps} from 'react';
import {Theme} from '../../theme/Theme';

const {colours} = Theme;
export const SVG = styled.svg`
  transition: height, width 200ms ease-out;
`;

interface AnimatedPathProps {
  duration: number;
  delay: number;
  colour: string;
  light: boolean;
  startRotation?: string;
  startColour?: string;
}

const animatedPathIn = ({
  duration,
  delay,
  colour,
  light,
  startRotation = '0deg',
  startColour = light ? colours.white : colours.black,
}: AnimatedPathProps) => {
  const animation = keyframes`
    from {
      transform:rotate(${startRotation});
      fill:${startColour};
      stroke:${startColour};
    }
    70% {
     fill:${colour};
     stroke:${colour};
    }
    to {
      transform:rotate(360deg);
      fill:${colour};
      stroke:${colour};
    }
  `;
  return css`
    animation: ${animation} ${duration}ms ease-in-out forwards;
    animation-delay: ${delay}ms;
  `;
};

export const AnimatedPath = styled.path<AnimatedPathProps>`
  stroke-width: 4px;
  transform-origin: 328.5px 328.5px;
  ${(props) => animatedPathIn(props)};
`;

export const animatedHoverPath = (
  duration: number,
  delay: number,
  visible: boolean,
  startRotation: string = '-360deg',
  startColour: string = colours.black
) => {
  const endColour = visible ? colours.black : '#00000000';
  const endRotation = '360deg';
  const animationIn = keyframes`
    from {
      transform:rotate(${startRotation});
      fill:${startColour};
      stroke:${startColour};
    }
    40% {
      fill:${startColour};
      stroke:${startColour};
    }
    70% {
      fill:${endColour};
      stroke:${endColour};
    }
    to {
      transform:rotate(${endRotation});
      fill:${endColour};
      stroke:${endColour};
    }
  `;
  const animationOut = keyframes`
    from {
      fill:${startColour};
      stroke:${startColour};
    }
    to {
      fill:${endColour};
      stroke:${endColour};
    }
  `;
  return css`
    animation: ${visible ? animationIn : animationOut} ${duration}ms
      ${visible ? 'ease-out' : 'ease-in'} forwards;
    animation-delay: ${delay}ms;
  `;
};

export const AnimatedHoverPath = styled.path<AnimatedPathProps>`
  stroke-width: 6px;
  transform-origin: 328.5px 328.5px;
`;

interface LogoContainerProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  animated: boolean;
  notInteractive: boolean;
}

export const LogoContainer = styled.div<LogoContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({notInteractive}) => (notInteractive ? 'auto' : 'pointer')};
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  ${({animated}) =>
    animated &&
    css`
      svg {
        g {
          ${AnimatedHoverPath} {
            ${[colours.yellow, colours.orange, colours.red, colours.blue].map(
              (colour, index) => css`
                :nth-child(${index + 6}) {
                  ${animatedHoverPath(100 + 40 * index, 0, false, '180deg')};
                }
              `
            )}
          }
        }
      }
    `}
  ${({notInteractive}) =>
    notInteractive
      ? ''
      : css`
          :hover {
            svg {
              g {
                ${AnimatedHoverPath} {
                  ${[
                    colours.yellow,
                    colours.orange,
                    colours.red,
                    colours.blue,
                  ].map(
                    (colour, index) => css`
                      :nth-child(${index + 6}) {
                        ${animatedHoverPath(
                          200 + 30 * index,
                          30 * index,
                          true,
                          '0deg',
                          colour
                        )}
                      }
                    `
                  )}
                }
              }
            }
          }
        `};
`;
