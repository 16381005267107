import styled from 'styled-components';
import {Theme} from '../../theme/Theme';

export interface GuttersProps {
  /** Css prop for max-width */
  maxWidth?: GutterMaxWidth;
  /** Css prop for padding-top and padding-bottom */
  padding?: boolean;
  /** Css prop for padding-top */
  paddingTop?: boolean;
  /** Css prop for padding-left and padding-right */
  paddingSides?: boolean;
}

export enum GutterMaxWidth {
  VERY_SMALL = '440px',
  SMALL = '600px',
  NORMAL = '960px',
  LARGE = '1200px',
  EXTRALARGE = '1400px',
}

export const Gutters = styled.div<GuttersProps>`
  margin: 0 auto;
  max-width: ${({maxWidth}) => (maxWidth ? maxWidth : GutterMaxWidth.NORMAL)};
  padding: ${({padding}) => (padding ? `${Theme.space[6]}px` : 0)} 0;
  ${({paddingTop}) => (paddingTop ? `padding-top:${Theme.space[6]}px` : '')};

  @media screen and (max-width: ${({maxWidth}) =>
      maxWidth ? maxWidth : GutterMaxWidth.NORMAL}) {
    padding-left: ${({paddingSides}) =>
      paddingSides ? `${Theme.space[4]}px` : 0};
    padding-right: ${({paddingSides}) =>
      paddingSides ? `${Theme.space[4]}px` : 0};
  }

  @media screen and (max-width: ${Theme.breakpoints[3]}px) {
    max-width: 95%;
  }
`;
