export enum DeviceOS {
  MAC_OS = 'Mac',
  WINDOWS = 'Windows',
  IOS = 'iOS',
  IPAD_OS = 'iPadOS',
  ANDROID = 'Android',
  LINUX = 'Linux',
  BLACK_BERRY = 'BlackBerry',
  SSR = 'ServerSideRendering - unable to detect OS',
  OTHER = 'Other',
}

export const detect = {
  os: (): DeviceOS => {
    if (typeof window === 'undefined') {
      return DeviceOS.SSR;
    }

    const userAgent = navigator.userAgent.toUpperCase();

    return /IPHONE|IPAD|IPOD/.test(userAgent)
      ? DeviceOS.IOS
      : /ANDROID/.test(userAgent)
      ? DeviceOS.ANDROID
      : /BLACKBERRY/.test(userAgent)
      ? DeviceOS.BLACK_BERRY
      : /MAC/.test(userAgent)
      ? detect.isTouchDevice()
        ? DeviceOS.IPAD_OS
        : DeviceOS.MAC_OS
      : /WIN/.test(userAgent)
      ? DeviceOS.WINDOWS
      : /LINUX/.test(userAgent)
      ? DeviceOS.LINUX
      : detect.isTouchDevice()
      ? DeviceOS.IPAD_OS
      : DeviceOS.MAC_OS;
  },
  isTouchDevice: (): boolean => {
    if (typeof window === 'undefined') {
      return false;
    }
    if (
      'ontouchstart' in window ||
      // @ts-ignore add typescript definitions for DocumentTouch
      (window?.DocumentTouch && document instanceof DocumentTouch)
    )
      return true;
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
      ''
    );
    return window.matchMedia(query).matches;
  },
  isMobile: (): boolean => {
    const os = detect.os();
    return (
      os === DeviceOS.ANDROID ||
      os === DeviceOS.IOS ||
      os === DeviceOS.BLACK_BERRY
    );
  },
  isDesktop: (): boolean => {
    const os = detect.os();
    return (
      os === DeviceOS.MAC_OS ||
      os === DeviceOS.WINDOWS ||
      os === DeviceOS.LINUX ||
      os === DeviceOS.IPAD_OS
    );
  },
  isCollectiveSupportedOS: (): boolean => {
    const os = detect.os();
    return os === DeviceOS.MAC_OS || os === DeviceOS.WINDOWS;
  },
};
