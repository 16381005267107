import styled from 'styled-components';
import {Theme} from '../../../theme/Theme';

const {colours, borderWidths, breakpoints, fonts, fontSizes} = Theme;

export const Nav = styled.nav`
  color: ${Theme.colours.white};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 2;
  user-select: none;
`;

export const List = styled.ul`
  list-style: none outside none;
  display: none;
  margin: 0;
  @media screen and (min-width: ${breakpoints[0]}px) {
    display: flex;
  }
`;

export const ListItem = styled.li`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${Theme.space[0]}px;
  font-family: ${fonts.heading};
  font-weight: bold;
  font-size: ${fontSizes[2]}px;

  transition: padding 400ms;

  @media screen and (min-width: ${breakpoints[0]}px) {
    padding-left: ${Theme.space[5]}px;
  }
  @media screen and (min-width: ${breakpoints[1]}px) {
    padding-left: ${Theme.space[6]}px;
  }
`;

export const NavLink = styled.a`
  cursor: pointer;
  color: ${colours.white};
  text-decoration: none;

  :after {
    content: ' ';
    display: block;
    background: ${colours.white};
    height: ${borderWidths[1]}px;
    width: 0;
    transition: width 230ms ease-out;
  }

  :hover {
    :after {
      width: 100%;
    }
  }
`;

interface NavGroupLinkProps {
  selected?: boolean;
}

export const NavGroupLink = styled(NavLink)<NavGroupLinkProps>`
  :after {
    width: ${({selected}) => (selected ? '100%' : 0)};
  }

  position: relative;
  svg {
    position: absolute;
    right: -26px;
    transition: opacity, top, 300ms ease-out;
    top: 5px;
    opacity: ${({selected}) => (selected ? 1 : 0.3)};
  }
`;

export const MenuToggleContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (min-width: ${breakpoints[0]}px) {
    display: none;
  }
`;
