import {Theme} from '../../../theme/Theme';
import styled from 'styled-components';

const {colours, borderWidths, breakpoints} = Theme;

export const SubNavLinkWrapper = styled.li`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-right: 0;
  transition: padding 400ms;
  width: 100%;

  :>nth-child(n)  {
    border-bottom: ${colours.white} solid ${borderWidths[0]}px;
  }
  @media screen and (min-width: ${breakpoints[0]}px) {
    width: auto;
    padding-right: ${Theme.space[4]}px;
    :last-child {
      padding-right: 0;
    }
  }
`;
