import styled, {keyframes} from 'styled-components';
import {InterpolationMode} from 'chroma-js';
import {Theme} from '../../theme/Theme';
import {createGradient} from '../../utils/colour';
import {Gutters, GuttersProps} from '../Spacing/Gutters';

const {colours, space, zIndices, shadows, fonts, fontSizes, breakpoints} =
  Theme;

const animateBar = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

interface StyledHeaderProps {
  thinMode: boolean;
}

export const StyledHeader = styled.header<StyledHeaderProps>`
  width: 100%;
  background: ${colours.black};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: ${zIndices.nav};
  box-shadow: ${({thinMode}) => (thinMode ? shadows[1] : shadows[2])};
  font-family: ${fonts.heading};
  font-size: ${fontSizes[1]}px;
`;

interface StyledGuttersProps extends GuttersProps {
  thinMode: boolean;
}

export const StyledGutters = styled(Gutters)<StyledGuttersProps>`
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  transition: height 200ms ease-out;
  height: ${({thinMode}) => (thinMode ? '58px' : '106px')};
  flex-shrink: 2;
  padding: ${space[1]}px 0;
`;

interface BarProps {
  /** flag to animate background */
  animate?: boolean;
  /** should the bar expand to reveal children on mobile */
  expanded?: boolean;
  /** should the bar expand to reveal children on desktop */
  expandedOnDesktop: boolean;
}

const gradient = createGradient(
  [colours.yellow, colours.orange, colours.red, colours.blue],
  'lch'
);

export const Bar = styled.div<BarProps>`
  width: 100%;
  min-height: 12px;
  height: ${({expanded}) => (expanded ? '100vh' : '12px')};
  transition: height 220ms ease-out;
  flex-shrink: 0;
  background: ${colours.black}
    linear-gradient(
      ${({expanded}) => (expanded ? '125deg' : '90deg')},
      ${gradient.join(',')}
    )
    0% 0% no-repeat padding-box;
  overflow: auto;
  background-size: ${({animate}) => (animate ? '150%' : '100%')};
  animation: ${animateBar}${({animate}) => (animate ? ' 2s linear infinite' : ' none')};

  @media screen and (min-width: ${breakpoints[0]}px) {
    height: ${({expandedOnDesktop}) => (expandedOnDesktop ? 'auto' : '12px')};
  }
`;
