import React, {FunctionComponent, useEffect, useState} from 'react';
import 'intersection-observer';
import {useStaticQuery, graphql} from 'gatsby';
import {useLocation} from '@reach/router';
import {Header} from './Header';
import {GlobalStyles} from '../theme/GlobalStyles';
import {LabeledNavLink, NavLinks} from './navigation/navigation.types';
import styled, {css} from 'styled-components';
import {Footer} from './Footer';
import {Theme} from '../theme/Theme';
import {TitleAndLinks} from '../types/FooterContent';
import * as loginUrl from '../utils/login-url';
import {PaymentsApi} from '@focusrite-novation/ampify-api';
import {isChosenPlugin} from '../lib/payment';

const Content = styled.div`
  flex-grow: 2;
  position: relative;
  > div:last-child {
    padding-bottom: ${Theme.space[6]}px;
  }
`;

interface ContentWrapperProps {
  fadeIn?: boolean;
  showBanner?: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>(
  ({fadeIn = true, showBanner = false}) => css`
    padding-top: ${showBanner ? '165px' : '118px'};

    background: ${Theme.colours.black};

    ${Content} {
      background: ${Theme.colours.white};
      animation: fadein 2s;

      @keyframes fadein {
        from {
          opacity: ${fadeIn ? '0' : '1'};
        }
        to {
          opacity: ${fadeIn ? '1' : '1'};
        }
      }
    }

    margin-bottom: auto;
  `
);

const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${Theme.colours.white};
`;

interface LayoutData {
  allContentfulProduct: {
    nodes: {
      title: string;
      slug: string;
    }[];
  };
  site: {
    siteMetadata: {
      ampifyApi: {
        baseUrl: string;
      };
      menu: {
        link: string;
        label: string;
      }[];
    };
  };
}

interface LayoutProps {
  groupedLinks: TitleAndLinks[];
  customerPortalBaseUrl: string;
  fadeIn?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({
  groupedLinks,
  children,
  customerPortalBaseUrl,
  fadeIn: fadeIn,
}) => {
  const [accountMenu, setAccountMenu] = useState<LabeledNavLink>({
    label: 'Account',
    link: '',
  });
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const location = useLocation();

  const data: LayoutData = useStaticQuery(graphql`
    query LayoutQuery {
      allContentfulProduct(sort: {order: ASC, fields: order}) {
        nodes {
          title
          slug
        }
      }
      site {
        siteMetadata {
          ampifyApi {
            baseUrl
          }
          menu {
            link
            label
          }
        }
      }
    }
  `);

  useEffect(() => {
    const checkBFCoupon = async () => {
      try {
        const paymentsApi = new PaymentsApi({
          basePath: data.site.siteMetadata.ampifyApi.baseUrl,
          isJsonMime: () => true,
        });
        const coupon = (await paymentsApi.getCoupon('bf50-rto')).data;

        if (coupon.state?.toLowerCase() === 'redeemable') {
          setShowBanner(true);
        }
      } catch (error) {}
    };

    if (!isChosenPlugin('fast-bundle', location)) {
      checkBFCoupon();
    }

    setAccountMenu({
      label: 'Account',
      link: loginUrl.myAccountUrl(customerPortalBaseUrl),
    });
  }, []);

  const removeFastText = (title: string) => title.slice(5);
  const links: NavLinks = [
    {
      label: 'Plugins',
      links: data.allContentfulProduct.nodes.map(({title, slug}) => ({
        label: removeFastText(title),
        link: `/products/${slug}`,
      })),
    },
    ...data.site.siteMetadata.menu,
    accountMenu,
  ];

  return (
    <Column>
      <GlobalStyles />
      <Header links={links} showBanner={showBanner} />
      <ContentWrapper fadeIn={fadeIn} showBanner={showBanner}>
        <Content>{children}</Content>
      </ContentWrapper>
      <Footer groupedLinks={groupedLinks} />
    </Column>
  );
};

export default Layout;
