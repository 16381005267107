import React, {FC, useState} from 'react';
import styled from 'styled-components';
import Theme from '../../theme';
import {TermsModal} from './';

const Banner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${Theme.space[2]}px;
  font-size: large;
  color: ${Theme.colours.white};
  background-color: ${Theme.colours.red};
  width: 100%;

  @media screen and (max-width: ${Theme.breakpoints[0]}px) {
    justify-content: center;
  }
  :hover {
    cursor: pointer;
  }

  p {
    margin-right: ${Theme.space[1]}px;
    text-align: center;
    line-height: 1.5rem;
  }

  .small {
    font-size: x-small;
  }

  .container {
    display: flex;
    flex-direction: row;
    position: relative;

    align-items: end;
    @media screen and (max-width: ${Theme.breakpoints[0]}px) {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const BlackFridayBanner: FC = () => {
  const [showTerms, setShowTerms] = useState(false);
  const closeModal = () => setShowTerms(false);

  return (
    <>
      <Banner data-testid="bf-banner" onClick={() => setShowTerms(true)}>
        <div className="container">
          <p>
            <b>50% OFF EVERYTHING - USE CODE BF50 AT CHECKOUT</b>
          </p>
        </div>
        <p className="small">
          This offer cannot be used in conjunction with any other offers or
          discounts. Click the banner for T&amp;Cs
        </p>
      </Banner>

      {showTerms && <TermsModal handleClose={closeModal} />}
    </>
  );
};
