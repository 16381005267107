import {WindowLocation} from '@reach/router';

export interface Country {
  code: string;
}

export const parseCountriesFromResponse = (countries: Country[]) => {
  return countries.map((country: Country) => country.code);
};

export const isPerpetual = (location: WindowLocation): boolean => {
  const params = new URLSearchParams(location.search);
  return Boolean(params.get('itemId'));
};

export const isChosenPlugin = (
  slug: string,
  location: WindowLocation
): boolean => {
  const params = new URLSearchParams(location.search);
  return params.get('slug') === slug || location.pathname.includes(slug);
};
