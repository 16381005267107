/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';

interface MetaTag {
  property: string;
  content: string;
}

interface SEOProps {
  description: string;
  lang: string;
  meta: MetaTag[];
  title: string;
  keywords?: string;
}

function SEO(props: SEOProps) {
  const {site} = useStaticQuery(
    graphql`
      query SEOQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = props.description || site.siteMetadata.description;

  if (!props.meta?.some((metaData) => metaData.property === 'og:title')) {
    props.meta.push(
      {
        property: 'og:title',
        content: props.title,
      },
      {
        property: 'twitter:title',
        content: props.title,
      }
    );
  }

  if (!props.meta?.some((metaData) => metaData.property === 'og:image')) {
    const location =
      typeof window !== 'undefined' ? window.location : {origin: '', href: ''};

    props.meta.push(
      {
        property: 'og:url',
        content: `${location.href}`,
      },
      {
        property: 'og:image',
        content:
          'https://collective.focusrite.com/images/collective-landscape-colour-dark-background-1200-630.png',
      },
      {
        property: 'og:image:width',
        content: '1200',
      },
      {
        property: 'og:image:height',
        content: '630',
      },
      {
        property: 'og:image',
        content:
          'https://collective.focusrite.com/images/collective-mark-dark-800-800.png',
      },
      {
        property: 'og:image:width',
        content: '800',
      },
      {
        property: 'og:image:height',
        content: '800',
      },
      {
        property: 'og:image:alt',
        content: props.title,
      },
      {
        property: 'og:image:type',
        content: 'image/png',
      },
      {
        property: 'twitter:card',
        content: `summary_large_image`,
      },
      {
        property: 'twitter:image',
        content:
          'https://collective.focusrite.com/images/collective-landscape-colour-dark-background-1200-630.png',
      }
    );
  }
  return (
    <Helmet
      htmlAttributes={{
        lang: props.lang,
      }}
      title={props.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {name: 'keywords', content: props.keywords},
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(props.meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
